import DropdownInputForObject from "components/Comman/Inputs/DropdownInputForObject";
import { LEAD_STAGES } from "helpers/constants/leads/stages";
import {
  LEAD_STATUS,
  LEAD_STATUS_BY_STAGE,
} from "helpers/constants/leads/status";
import SimpleInput from "components/Comman/Inputs/SimpleInput";
import {
  Envelope,
  Handshake,
  Path,
  Pencil,
  Phone,
  PlusCircle,
  User,
  X,
} from "@phosphor-icons/react";
import DateInput from "components/Comman/Inputs/DateInput";
import { useState } from "react";
import ChronosButton from "components/Comman/Buttons";
import AddMembers from "components/Modals/AddMembers";

function LeadDetails({ data, setData, channel, users }) {
  const [addMember, setAddMember] = useState({ isOpen: false });

  return (
    <>
      <AddMembers
        isOpen={addMember?.isOpen}
        closeModal={() => {
          setAddMember({ isOpen: false });
        }}
        type={addMember?.type}
        selected={addMember?.selected}
        selectOne={addMember?.type == "owner"}
        onUpdate={(val) => {
          switch (addMember?.type) {
            case "owner":
              if (val?.length > 0)
                setData({ ...data, owner: val[0], owner_id: val[0]?.id });
              break;
            default:
              setData({
                ...data,
                collaborators: val,
                collaboratorsId: val?.map((v) => v?.id),
              });
          }
        }}
      />
      {console.log(data)}
      <div className="flex w-full flex-col items-start space-y-10 max-h-[60vh] overflow-y-auto">
        <div className="flex flex-col items-start w-full space-y-8">
          <label
            htmlFor="Title"
            className="text-base text-primary-gray-800 leading-4 font-poppins font-medium"
          >
            Lead Details
          </label>
          <div className="px-6 grid grid-cols-7 gap-x-4 w-full">
            <Path className="text-primary-purple-dark rotate-90" size={24} />
            <div className="col-span-2 input-label">Lead Source:</div>
            <div className="col-span-4">
              <SimpleInput
                srOnly
                field="source"
                details={data}
                setDetails={setData}
                placeholder="Start typing..."
              />
            </div>
          </div>
          <div className="px-6 grid grid-cols-7 gap-x-4 w-full">
            <Handshake
              className="text-primary-purple-dark"
              size={24}
              weight="fill"
            />
            <div className="col-span-2 input-label">Close Probability:</div>
            <div className="col-span-4">
              <SimpleInput
                srOnly
                field="closeProbability"
                details={data}
                setDetails={setData}
                placeholder="Start typing..."
              />
            </div>
          </div>
          <div className="px-6 grid grid-cols-7 gap-x-4 w-full">
            <Handshake
              className="text-primary-purple-dark"
              size={24}
              weight="fill"
            />
            <div className="col-span-2 input-label">Last Action:</div>
            <div className="col-span-4">
              <SimpleInput
                srOnly
                field="lastAction"
                details={data}
                setDetails={setData}
                placeholder="Start typing..."
              />
            </div>
          </div>
          <div className="px-6 grid grid-cols-7 gap-x-4 w-full">
            <Handshake
              className="text-primary-purple-dark"
              size={24}
              weight="fill"
            />
            <div className="col-span-2 input-label">Next Step:</div>
            <div className="col-span-4">
              <SimpleInput
                srOnly
                field="nextStep"
                details={data}
                setDetails={setData}
                placeholder="Start typing..."
              />
            </div>
          </div>
          <div className="px-6 grid grid-cols-6 gap-x-4 w-full">
            <div className="col-span-3">
              <label htmlFor="Title" className="input-label">
                Obtained on:
              </label>{" "}
              <DateInput
                date={data?.obtainedOn}
                onChange={(val) => {
                  setData({ ...data, obtainedOn: val });
                }}
              />
            </div>
            <div className="col-span-3">
              <label htmlFor="Title" className="input-label">
                Closed on:
              </label>{" "}
              <DateInput
                date={data?.closedOn}
                onChange={(val) => {
                  setData({ ...data, closedOn: val });
                }}
              />
            </div>
          </div>
          <div className="px-6 grid grid-cols-6 gap-x-4 w-full">
            <div className="col-span-3">
              <DropdownInputForObject
                label="Stage"
                field="stage"
                list={LEAD_STAGES?.map((s) => ({ label: s, value: s }))}
                details={data}
                setDetails={setData}
              />
            </div>
            <div className="col-span-3">
              <DropdownInputForObject
                label="Status"
                field="status"
                list={(data?.stage
                  ? LEAD_STATUS_BY_STAGE[data?.stage]
                  : LEAD_STATUS
                )?.map((s) => ({ label: s, value: s }))}
                details={data}
                setDetails={setData}
              />
            </div>
          </div>

          <div className="px-6 grid grid-cols-6 gap-x-4 w-full">
            <div className="col-span-6">
              <DropdownInputForObject
                label="Channel"
                field="channelId"
                list={channel?.map((s) => ({ label: s?.name, value: s?.id }))}
                details={data}
                setDetails={setData}
              />
            </div>
          </div>
        </div>{" "}
        <div className="flex flex-col items-start w-full space-y-8">
          <label
            htmlFor="Title"
            className="text-base text-primary-gray-800 leading-4 font-poppins font-medium"
          >
            Lead Contact Details (Point of Contact)
          </label>
          <div className="px-6 grid grid-cols-7 gap-x-4 w-full">
            <User className="text-primary-purple-dark" size={24} />
            <div className="col-span-2 input-label">Name:</div>
            <div className="col-span-4">
              <SimpleInput
                srOnly
                field="contactName"
                details={data}
                setDetails={setData}
                placeholder="Start typing..."
              />
            </div>
          </div>
          <div className="px-6 grid grid-cols-7 gap-x-4 w-full">
            <Envelope className="text-primary-purple-dark" size={24} />
            <div className="col-span-2 input-label">Email:</div>
            <div className="col-span-4">
              <SimpleInput
                srOnly
                field="contactEmail"
                details={data}
                setDetails={setData}
                placeholder="Start typing..."
              />
            </div>
          </div>
          <div className="px-6 grid grid-cols-7 gap-x-4 w-full">
            <Phone className="text-primary-purple-dark" size={24} />
            <div className="col-span-2 input-label">Mobile:</div>
            <div className="col-span-4">
              <SimpleInput
                srOnly
                field="contactPhoneNumber"
                details={data}
                setDetails={setData}
                placeholder="Start typing..."
              />
            </div>
          </div>
        </div>
        <div className="flex flex-col items-start w-full space-y-8">
          <label
            htmlFor="Title"
            className="text-base text-primary-gray-800 leading-4 font-poppins font-medium"
          >
            Team Details
          </label>
          <div className="px-6 w-full">
            <label htmlFor="Title" className="input-label">
              Owner
            </label>
            <div className="flex flex-row items-center space-x-4 mt-4">
              {data?.owner ? (
                <>
                  <div className="flex flex-row items-center space-x-3">
                    {data?.owner?.image ? (
                      <img
                        src={data?.owner?.image?.url}
                        className="w-6 h-6 object-contain rounded-full"
                      />
                    ) : (
                      <div className="w-6 h-6 text-primary-purple-dark object-contain rounded-full bg-primary-purple-lightest flex flex-row items-center justify-center">
                        <User size={20} />
                      </div>
                    )}
                    <p className="font-inter text-primary-gray-800 text-sm font-thin">
                      {data?.owner?.first_name}
                    </p>
                  </div>
                  <Pencil
                    className="text-primary-purple-dark"
                    size={16}
                    onClick={() => {
                      setAddMember({
                        isOpen: true,
                        type: "owner",
                        selected: [data?.owner],
                      });
                    }}
                  />
                </>
              ) : (
                <ChronosButton
                  underline
                  tertiary
                  text="Add Owner"
                  onClick={() => {
                    setAddMember({
                      isOpen: true,
                      type: "owner",
                      selected: [data?.owner],
                    });
                  }}
                  iconReverse
                  icon={
                    <PlusCircle
                      className="text-primary-purple-dark mr-2"
                      size={16}
                    />
                  }
                />
              )}
            </div>
          </div>
          <div className="px-6 w-full">
            <label htmlFor="Title" className="input-label">
              Collaborators
            </label>
            <div className="flex flex-row items-center flex-wrap mt-4">
              {data?.collaborators && data?.collaborators?.length > 0 ? (
                <>
                  {data?.collaborators?.map((c) => {
                    return (
                      <div className="flex flex-row items-center space-x-2.5 m-1">
                        <div className="flex flex-row items-center space-x-3">
                          {c?.image ? (
                            <img
                              src={c?.image?.url}
                              className="w-6 h-6 object-contain rounded-full"
                            />
                          ) : (
                            <div className="w-6 h-6 text-primary-purple-dark object-contain rounded-full bg-primary-purple-lightest flex flex-row items-center justify-center">
                              <User size={20} />
                            </div>
                          )}
                          <p className="font-inter text-primary-gray-800 text-sm font-thin">
                            {c?.first_name}
                          </p>
                        </div>
                        <X
                          className="text-primary-purple-dark"
                          size={16}
                          onClick={() => {
                            let temp = data?.collaborators || [];
                            temp = temp?.filter((t) => t?.id !== c?.id);
                            setData({
                              ...data,
                              collaborators: temp,
                              collaboratorsId: temp?.map((i) => i?.id),
                            });
                          }}
                        />
                      </div>
                    );
                  })}
                </>
              ) : (
                <ChronosButton
                  underline
                  tertiary
                  text="Add Collaborators"
                  onClick={() => {
                    setAddMember({
                      isOpen: true,
                      type: "collaborators",
                      selected: data?.collaborators,
                    });
                  }}
                  iconReverse
                  icon={
                    <PlusCircle
                      className="text-primary-purple-dark mr-2"
                      size={16}
                    />
                  }
                />
              )}
            </div>
          </div>
          <div className="px-6 w-full">
            <label htmlFor="Title" className="input-label">
              Last Contacted by:
            </label>
            <div className="grid grid-cols-6 gap-x-4 w-full">
              <div className="col-span-6">
                <DropdownInputForObject
                  srOnly
                  label="Last Contacted by"
                  field="lastContactedBy_id"
                  list={users?.map((s) => ({
                    label: s?.first_name,
                    value: s?.id,
                  }))}
                  details={data}
                  setDetails={setData}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default LeadDetails;

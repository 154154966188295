import Drawer from "@mui/material/Drawer";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as API from "config/APIs/sales/leads";
import { showToast } from "redux/toaster";
import ChronosButton from "components/Comman/Buttons";
import { ArrowLeft, Check } from "@phosphor-icons/react";
import DropdownInput from "components/Comman/Inputs/DropdownInput";

import Tabs from "components/Comman/Tabs";
import LeadDetails from "./LeadDetails";
import CompanyDetails from "./CompanyDetails";
import { fetchAllChannel } from "redux/channel";
import { fetchAllUsers } from "redux/users";
import AutoComplete from "components/Comman/Inputs/InputSearch";

const defaultValue = {};

let tabs = [
  { label: "Lead Details", value: "lead" },
  { label: "Company Details", value: "company" },
];

function CreateLeadModal({ closeModal, isOpen, onCreate }) {
  const dispatch = useDispatch();
  const dictionary = useSelector((state) => state?.dictionary?.list);
  const channel = useSelector((state) => state?.channel?.list);
  const users = useSelector((state) => state?.users?.list);
  const [data, setData] = useState(defaultValue);
  const [fieldErrors, setFieldErrors] = useState({});
  const [creating, setCreating] = useState(false);
  const [selectedTab, setSelectedTab] = useState("lead");

  useEffect(() => {
    if (channel?.length == 0) dispatch(fetchAllChannel());
    if (users?.length == 0) dispatch(fetchAllUsers());
  }, []);

  const showSuccessNotification = (message) => {
    dispatch(showToast({ message }));
  };

  const showErrorNotification = (message) => {
    dispatch(showToast({ message, type: "error" }));
  };

  const handleCreate = async () => {
    setCreating(true);

    try {
      const response = await API.createLead(data);
      onCreate();
      closeModal();
      setData({});
      setSelectedTab("lead");
      showSuccessNotification("Lead created successfully!");
    } catch (err) {
      console.log("Error", err);
      switch (err.response?.status) {
        case 422:
          let error = {},
            { data } = err.response.data;
          for (let key of Object.keys(data)) {
            if (key.split(".")[0] === "task")
              error[key.split(".")[1]] = data[key];
          }
          setFieldErrors(error);
        case 401:
          console.log(err?.response);
        default:
          showErrorNotification(err.response?.data?.message);
      }
    }
    setCreating(false);
  };

  return (
    <Drawer
      anchor={window.innerWidth < 1024 ? "bottom" : "right"}
      PaperProps={{
        style: {
          maxHeight: "100vh",
          width: window.innerWidth < 1024 ? "100%" : "516px",
        },
      }}
      open={isOpen}
      onClose={() => {
        closeModal();
        setData(defaultValue);
      }}
      transitionDuration={250}
    >
      <div className="md:max-w-xl p-4 mx-auto max-h-screen h-screen overflow-hidden w-full bg-white transform transition-all ease-in-out duration-150">
        <div className="flex flex-row items-center justify-between py-4 space-x-4">
          <div className="flex flex-row items-center justify-start space-x-4">
            <ArrowLeft
              onClick={() => {
                closeModal();
                setData(defaultValue);
              }}
              size={24}
            />
            <h5 className="font-poppins font-medium text-xl text-primary-purple-dark flex flex-col items-start">
              New Lead
            </h5>
          </div>
          <div className="flex flex-row items-center justify-end space-x-4">
            {window.innerWidth > 1024 && (
              <ChronosButton
                text="Cancel"
                secondary
                onClick={() => {
                  closeModal();
                  setData(defaultValue);
                }}
              />
            )}
            <ChronosButton
              text="Add Lead"
              primary
              icon={<Check size={16} className="mr-1.5" />}
              iconReverse
              onClick={() => {
                handleCreate();
              }}
            />
          </div>
        </div>

        <AutoComplete
          srOnly
          label="Dictionary"
          placeholder="Search by company name or founder"
          value={data?.dictionaryId}
          setValue={(val) => {
            setData({
              ...data,
              dictionaryId: val?.value,
              companyName: val?.label,
              companyLocation: val?.location,
            });
          }}
          list={(dictionary || [])?.map((s) => ({
            label: s?.name,
            value: s?.id,
            location: s?.location,
          }))}
        />
        {/* <div className="flex flex-col items-start w-full space-y-4">
          <label
            htmlFor="Title"
            className="text-base text-primary-gray-800 leading-4 font-poppins font-medium"
          >
            Search for Company
          </label>
          <DropdownInput
            srOnly
            label="Dictionary"
            field="dictionaryId"
            textField="companyName"
            placeholder="Search by company name or founder"
            list={(dictionary || [])?.map((s) => ({
              label: s?.name,
              value: s?.id,
            }))}
            value={data?.dictionaryId}
            setValue={(val) => {
              setData({
                ...data,
                dictionaryId: val,
                companyName: dictionary?.find((a) => a?.id == val)?.name,
              });
            }}
          />
        </div> */}
        <div className="mt-5 flex w-full flex-col items-start space-y-10 transition-all ease-in-out duration-150">
          <Tabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          {selectedTab == "lead" && (
            <LeadDetails
              data={data}
              setData={setData}
              channel={channel}
              users={users}
            />
          )}
          {selectedTab == "company" && (
            <CompanyDetails data={data} setData={setData} />
          )}
        </div>
      </div>
    </Drawer>
  );
}

export default CreateLeadModal;

import {
  SHORTLIST,
  TOUCHBASE,
  REQUIREMENT_GATHERING,
  SOLUTION,
  PROPOSAL,
  APPROVAL,
  INVOICING,
} from "./stages";

export const STATUS_NEW = "New";
export const STATUS_UNQUALIFIED = "Unqualified";
export const STATUS_CONTACTED = "Contacted";
export const STATUS_NO_RESPONSE = "No Response";
export const STATUS_INITIAL_CALL = "Initial Call Scheduled";
export const STATUS_INITIAL_TOUCHBASE = "Initial Touchbase Done";
export const STATUS_QUALIFIED = "Qualified";
export const STATUS_INITIATED = "Initiated";
export const STATUS_CALL_SCHEDULED = "Call Scheduled";
export const STATUS_COMPLETE = "Complete";
export const STATUS_PREPARING = "Preparing";
export const STATUS_SIGNOFF_OBTAINED = "Signoff Obtained";
export const STATUS_NEGOTIATION_CALL = "Negotiation Call Scheduled";
export const STATUS_PROPOSAL_SIGNED = "Proposal Signed";
export const STATUS_INVOICE_GENERATED = "Invoice Generated";
export const STATUS_PENDING_CLEARANCE = "Pending Clearance";
export const STATUS_SERVICE_INITIATED = "Project/Service Initiated";
export const STATUS_ONBOARDED = "Onboarded";

export const LEAD_STATUS = [
  STATUS_NEW,
  STATUS_UNQUALIFIED,
  STATUS_CONTACTED,
  STATUS_NO_RESPONSE,
  STATUS_INITIAL_CALL,
  STATUS_INITIAL_TOUCHBASE,
  STATUS_QUALIFIED,
  STATUS_INITIATED,
  STATUS_CALL_SCHEDULED,
  STATUS_COMPLETE,
  STATUS_PREPARING,
  STATUS_SIGNOFF_OBTAINED,
  STATUS_NEGOTIATION_CALL,
  STATUS_PROPOSAL_SIGNED,
  STATUS_INVOICE_GENERATED,
  STATUS_PENDING_CLEARANCE,
  STATUS_SERVICE_INITIATED,
  STATUS_ONBOARDED,
];

export const LEAD_STATUS_BY_STAGE = {
  [SHORTLIST]: [STATUS_NEW, STATUS_UNQUALIFIED],
  [TOUCHBASE]: [
    STATUS_CONTACTED,
    STATUS_NO_RESPONSE,
    STATUS_INITIAL_CALL,
    STATUS_INITIAL_TOUCHBASE,
    STATUS_QUALIFIED,
    STATUS_UNQUALIFIED,
  ],
  [REQUIREMENT_GATHERING]: [
    STATUS_NO_RESPONSE,
    STATUS_INITIATED,
    STATUS_CALL_SCHEDULED,
    STATUS_COMPLETE,
  ],
  [SOLUTION]: [STATUS_PREPARING, STATUS_SIGNOFF_OBTAINED],
  [PROPOSAL]: [
    STATUS_PREPARING,
    STATUS_NEGOTIATION_CALL,
    STATUS_PROPOSAL_SIGNED,
  ],
  [APPROVAL]: [STATUS_INVOICE_GENERATED],
  [INVOICING]: [
    STATUS_INVOICE_GENERATED,
    STATUS_PENDING_CLEARANCE,
    STATUS_SERVICE_INITIATED,
    STATUS_ONBOARDED,
  ],
};

import React from "react";
import LeadTable from "./Table";

function TableView({ data }) {
  return (
    <>
      <LeadTable heading="Pipeline" color="#C44EB9" data={data} />
      {/* <LeadTable heading="Closed Deals" color="#2BB656" /> */}
    </>
  );
}

export default TableView;

import { IoMdMore } from "react-icons/io";

export default function TalentRequestCard() {
  return (
    <div className="font-lato p-4 bg-white rounded-2xl shadow-customLarge flex flex-col gap-6">
      <div className="flex justify-between items-center">
        <p className="text-lg font-bold text-primary-gray-800">Role Name</p>
        <IoMdMore className="text-2xl" />
      </div>

      <div className="">
        <p className="text-sm text-[#363430] font-normal mb-6">
          Openings: <strong>1</strong>
        </p>
        <p className="text-xs text-[#363430] font-normal mb-1">Details:</p>
        <p className="text-xs text-[#363430] font-semibold">
          Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet,
          consectetur, adipisci velit, sed qu
        </p>
      </div>

      <div className="flex justify-between items-center">
        <p className="text-[#696763] text-xs font-semibold">Requested Today</p>
        <button className="py-3 px-5 rounded-[8px] text-[#3996E3] underline font-bold">
          View Details
        </button>
      </div>
    </div>
  );
}

import { FiMoreVertical } from "react-icons/fi";
import { ReactComponent as Fire } from "assets/icons/Fire.svg";

export default function Card() {
  return (
    <div className="p-4 bg-white rounded-2xl shadow-customLarge flex flex-col gap-6">
      <div className="flex gap-5 items-start">
        <img
          src="/assets/images/defaultCard.png"
          alt="defaultCard"
          className="w-20 h-20 rounded"
        />
        <div className="flex justify-between w-full">
          <div className="flex flex-col">
            <p className="text-primary-gray-800 text-xl font-bold">
              Startup Name Here
            </p>
            <p className="text-black text-sm font-light">
              Client since Oct 2022
            </p>
          </div>
          <FiMoreVertical className="text-xl" />
        </div>
      </div>

      <div className="flex w-full">
        <div className="flex flex-col w-full items-center justify-center">
          <p className="text-black text-[24px] font-bold flex relative items-center">
            <Fire className="absolute -left-6 h-5 w-5" /> <span>1</span>
          </p>
          <p className="text-[#9C9A96] text-sm">Talent Requirements</p>
        </div>
        <div className="flex flex-col w-full items-center justify-center">
          <p className="text-black text-[24px] font-bold">0</p>
          <p className="text-[#9C9A96] text-sm">Ongoing Requests</p>
        </div>
      </div>

      <div className="flex justify-between items-center">
        <div className="text-[#696763] font-semibold text-xs flex items-center gap-1">
          Added Today
        </div>
        <button className="py-3 px-5 rounded-[8px] text-[#3996E3] underline font-bold">
          View Details
        </button>
      </div>
    </div>
  );
}

import { IoMdCloseCircle } from "react-icons/io";

export default function NewTalentRequestModal({ isOpen, handleClose }) {
  if (!isOpen) return null;
  return (
    <section className="fixed top-0 left-0 w-full h-full bg-black/50 z-50">
      <div className="bg-white absolute w-full h-full bottom-0 max-h-[640px]  rounded-t-2xl shadow-custom py-2">
        <IoMdCloseCircle
          className="text-5xl text-[#363430] absolute -top-12 left-1/2 -translate-x-1/2 cursor-pointer"
          onClick={handleClose}
        />
        <h3 className="text-2xl font-bold tracking-[1px] px-2 py-4 mb-8 border-b border-gray-200">
          New Talent Request
        </h3>

        <div className="flex flex-col justify-between">
          <form className="px-6 relative">
            <div className="mb-10 flex gap-4 flex-col">
              <label className="block text-xs font-semibold text-gray-700">
                Role
              </label>

              <select className="mt-1 block w-full text-base placeholder:text-[#cfcdc9] border-0 !border-b border-b-[#E7E6E5] px-4 focus:ring-0">
                <option value="0">Select Role</option>
                <option value="1">Role 1</option>
                <option value="2">Role 2</option>
              </select>
            </div>
            <div className="mb-10 flex gap-4 flex-col">
              <label
                className="block text-xs font-semibold text-gray-700"
                htmlFor="openings"
              >
                Openings
              </label>
              <input
                type="text"
                name="openings"
                placeholder="1"
                className="mt-1 block w-full text-base placeholder:text-[#cfcdc9] border-0 !border-b border-b-[#E7E6E5] px-4 focus:ring-0"
              />
            </div>
            <div className="mb-10 flex gap-4 flex-col">
              <label
                className="block text-xs font-semibold text-gray-700"
                htmlFor="details"
              >
                Details
              </label>
              <textarea
                className="mt-1 block w-full text-base placeholder:text-[#cfcdc9] border-0 !border-b border-b-[#E7E6E5] px-4 focus:ring-0"
                rows="4"
                placeholder="Start typing here..."
              ></textarea>
            </div>
          </form>
          <div className="flex w-full shadow-custom absolute bottom-0">
            <button className="bg-white text-gray-500 w-full py-6">
              Save Draft
            </button>
            <button
              type="submit"
              className="bg-[#3996E3] text-white w-full py-6"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

import axios from "axios";
import config from "config/APIEndpoints";

const BASE_API_URL = config.getEndpoint();
const ADMIN_ENDPOINT = BASE_API_URL + "/api/v1";
const ROLODEX_ENDPOINT = BASE_API_URL + "/api/v1/rolodex";

export { BASE_API_URL };

// Util
export const getHeader = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "Content-Type": "application/json",
    },
  };
};

export const getMultipartHeader = () => {
  return {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
      "content-type": "multipart/form-data",
    },
  };
};

export const instance = axios.create({
  baseURL: ADMIN_ENDPOINT,
});
export const rolodexInstance = axios.create({
  baseURL: ROLODEX_ENDPOINT,
});

export const refreshToken = () =>
  rolodexInstance.get("/auth/token", getHeader());

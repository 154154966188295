import { useEffect, useState } from "react";
import { fetchLeads } from "config/APIs/sales/leads";
import { BoxedTabs } from "components/Comman/Tabs";
import TableView from "components/Leads/TableView";
import BoardView from "components/Leads/BoardView";
import ChronosButton from "components/Comman/Buttons";
import CreateLeadModal from "components/Leads/Modal/CreateLead";
const tabs = [
  { label: "Main Table", value: "main" },
  { label: "Kanban", value: "kanban" },
  { label: "Goals", value: "goals" },
];

function Leads() {
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
  });

  const [tableData, setTableData] = useState([]);
  const [selectedTab, setSelectedTab] = useState("main");
  const [openAdd, setOpenAdd] = useState(false);
  const fetchTableData = async (obj) => {
    const response = await fetchLeads(obj);
    const data = response?.data?.data;
    setTableData(data);
  };
  useEffect(() => {
    if (tableData.length === 0) {
      fetchTableData();
    }
  }, []);

  return (
    <>
      <CreateLeadModal
        isOpen={openAdd}
        closeModal={() => {
          setOpenAdd(false);
        }}
        onCreate={() => {
          fetchTableData();
        }}
      />
      <div className="bg-white rounded-2xl p-6 h-full space-y-4">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <BoxedTabs
            tabs={tabs}
            selectedTab={selectedTab}
            setSelectedTab={setSelectedTab}
          />
          <ChronosButton
            text="+ Add New Lead"
            primary
            onClick={() => {
              setOpenAdd(true);
            }}
          />
        </div>
        {selectedTab == "main" && <TableView data={tableData} />}
        {selectedTab == "kanban" && <BoardView data={tableData} />}
      </div>
    </>
  );
}

export default Leads;

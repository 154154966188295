export default function RequirementCard() {
  return (
    <div className="font-lato p-4 bg-white rounded-2xl shadow-customLarge flex flex-col gap-6">
      <div className="mb-6">
        <p className="text-2xs text-[#9c9a96] font-semibold">Request #001</p>
        <p className="text-base font-bold text-primary-gray-800">
          Feb 23, 2024
        </p>
      </div>

      <div className="mb-6">
        <p className="text-sm text-[#9c9a96] font-bold mb-2">Current Needs</p>
        <div className="flex flex-wrap gap-x-5 gap-y-4">
          {[...Array(4)].map((_, index) => (
            <div className="flex items-center gap-2" key={index}>
              <input
                type="checkbox"
                className="form-checkbox h-4 w-4 text-primary-gray-800 rounded-sm focus:!ring-primary-gray-800"
              />
              <p className="text-[14px] font-normal text-primary-gray-800">
                Work on Branding
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-col gap-2">
        {[...Array(3)].map((_, i) => (
          <div
            key={i}
            className="py-2 px-4 border border-[#E7E6E5] rounded-full w-max font-bold text-sm text-primary-blue-700"
          >
            UI/UX Designer
          </div>
        ))}
      </div>
    </div>
  );
}

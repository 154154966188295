import React from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
ChartJS.register(ArcElement, Tooltip, Legend);

let chartData = {
  labels: ["Red", "Blue", "Yellow", "Green"],
  datasets: [
    {
      label: "# of Leads",
      data: [50, 30, 20, 5],
      backgroundColor: ["#903795", "#F282F5", "#451451", "#F4C4F7"],
      borderWidth: 0,
    },
  ],
};

function LeadDistribution() {
  return (
    <div className="bg-white rounded-2xl p-6 h-full space-y-6">
      <p className="font-poppins text-primary-gray-800 font-medium leading-5 text-lg">
        Lead Distribution
      </p>
      <div className="w-full block">
        <Pie
          data={chartData}
          style={{
            width: "160px",
            height: "160px",
          }}
          options={{
            responsive: true,
            plugins: {
              legend: {
                position: "bottom",
                align: "center",
                labels: {
                  boxWidth: 10,
                  boxHeight: 10,
                  padding: 10,
                  font: {
                    size: 10,
                  },
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
}

export default LeadDistribution;

import { useState } from "react";
import SearchBox from "components/Comman/Inputs/SearchBox";
import EmptyState from "components/Comman/EmptyState";
import CardsView from "components/FounderDictionary/CardsView";
import { useSelector } from "react-redux";

function FounderDictionary() {
  const dictionary = useSelector((state) => state?.dictionary?.list);
  const [search, setSearch] = useState({
    searchText: "",
    isSearch: false,
  });

  return (
    <>
      <div className="px-2.5 pt-2.5 lg:px-0 lg:w-2/3">
        <SearchBox
          placeholder="Start typing to find..."
          search={search}
          setSearch={setSearch}
        />
      </div>

      <div className="max-h-70vh lg:max-h-80vh overflow-y-scroll mt-5 px-2.5 lg:px-0">
        {dictionary.length !== 0 ? (
          <>
            <CardsView data={dictionary} />
          </>
        ) : (
          <div className="h-60vh flex flex-row items-center justify-center">
            <EmptyState text="There are no startups in the rolodex currently!" />
          </div>
        )}
      </div>
    </>
  );
}

export default FounderDictionary;

import { createSlice } from "@reduxjs/toolkit";
import { fetchChannels } from "config/APIs/sales/channel";

const initialState = {
  list: [],
  fetching: true,
};

// Storing members
export const channel = createSlice({
  name: "channel",
  initialState,
  reducers: {
    updateList: (state, action) => {
      let list = action.payload.list;
      state.list = list;
      state.fetching = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateList } = channel.actions;

export default channel.reducer;

export function fetchAllChannel() {
  return async (dispatch) => {
    try {
      const response = await fetchChannels();
      if (response.status === 200) {
        let data = response.data.data;

        dispatch(updateList({ list: data }));
      }
    } catch (err) {
      console.log("Fetch all channel: ", err);
    }
  };
}

import { getHeader, rolodexInstance } from "config/APIs";

export const fetchChannels = (query = {}) => {
  return rolodexInstance.get(
    `/channel?` + new URLSearchParams(query),
    getHeader()
  );
};

export const getOneChannel = (id) => {
  return rolodexInstance.get(`/channel/${id}`, getHeader());
};

export const createChannel = (body) => {
  return rolodexInstance.post(`/channel`, body, getHeader());
};
export const updateChannel = (id, body) => {
  return rolodexInstance.patch(`/channel/${id}`, body, getHeader());
};

export const deleteChannel = (id) => {
  return rolodexInstance.delete(`/channel/${id}`, getHeader());
};

import { MagnifyingGlass } from "@phosphor-icons/react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useAsyncDebounce } from "react-table";

function SearchBox({ placeholder, search, setSearch }) {
  const dark = useSelector((state) => state.appConfig.dark);
  const [value, setValue] = useState(search);

  const onChange = useAsyncDebounce((value) => {
    setSearch({
      ...search,
      searchText: value,
      isSearch: value !== "",
    });
  }, 200);

  return (
    <div
      className={`flex flex-row items-center justify-start space-x-3 px-4 h-10 theme-input rounded-full w-full ${
        dark ? "text-white" : "text-black"
      }`}
    >
      <MagnifyingGlass size={20} />
      <input
        placeholder={placeholder}
        value={value?.searchText}
        name="search"
        className={`text-xs font-inter bg-transparent ${
          dark ? "placeholder:text-white" : "placeholder:text-primary-gray-250"
        } w-11/12 focus:outline-none border-0 border-primary-gray-250`}
        onChange={(e) => {
          e.target.value = e.target.value.trimStart();
          setValue(e.target.value);
          onChange(e.target.value);
        }}
      ></input>
    </div>
  );
}

export default SearchBox;

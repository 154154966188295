import {
  DASHBOARD,
  DICTIONARY,
  HOT_LEADS,
  LEADS,
  MY_CLIENTS,
  PROSPECT_BANK,
} from "helpers/constants/tabs";
import { checkAppAccess } from "helpers/utils/accessCheck";
import Dashboard from "pages/Dashboard";
import FounderDictionary from "pages/FounderDictionary";
import HotLeadsPage from "pages/HotLeads";
import Leads from "pages/Leads";
import MyClientsPage from "pages/MyClients";
import ClientPage from "pages/MyClients/Client";
import ProspectBankPage from "pages/ProspectBank";
import React from "react";
import { Redirect } from "react-router-dom";

// Authenticated Paths

// Array of routes only a logged in user can access
const privateRoutes = [
  {
    name: DASHBOARD,
    description: "",
    tab: DASHBOARD,
    path: "/dashboard",
    component: Dashboard,
  },
  {
    name: DICTIONARY,
    description: "",
    tab: DICTIONARY,
    path: "/founderDictionary",
    component: FounderDictionary,
  },
  {
    name: LEADS,
    description: "",
    tab: LEADS,
    path: "/leads",
    component: Leads,
  },
  {
    name: LEADS,
    description: "",
    tab: LEADS,
    path: "/leads",
    component: Leads,
  },
  {
    name: HOT_LEADS,
    description: "",
    tab: HOT_LEADS,
    path: "/hot-leads",
    component: HotLeadsPage,
  },
  {
    name: PROSPECT_BANK,
    description: "",
    tab: PROSPECT_BANK,
    path: "/prospect-bank",
    component: ProspectBankPage,
  },
  {
    name: MY_CLIENTS,
    description: "",
    tab: MY_CLIENTS,
    path: "/my-clients",
    component: MyClientsPage,
  },
  {
    name: "Client",
    description: "",
    tab: MY_CLIENTS,
    path: "/clients/:id",
    component: ClientPage,
  },
  {
    path: "/",
    exact: true,
    component: () =>
      localStorage.getItem("token") ? (
        checkAppAccess() ? (
          <Redirect to="/dashboard" />
        ) : (
          <Redirect to="/accessDenied" />
        )
      ) : (
        <Redirect to="/auth/signIn" />
      ),
  },
];

export { privateRoutes };

import { ReactComponent as Fire } from "assets/icons/Fire.svg";

export default function Card() {
  return (
    <div className="p-4 bg-white rounded-2xl shadow-customLarge flex flex-col gap-6">
      <div className="flex justify-between items-center gap-6">
        <p className="w-full text-xl font-semibold text-primary-gray-800">
          Startup Name
        </p>
        <div className="max-w-[24px]">
          <Fire />
        </div>
      </div>

      <div className="flex gap-5 items-start">
        <p className="text-base font-bold">Needs:</p>
        <div className="flex flex-wrap gap-x-5 gap-y-4">
          {[...Array(4)].map((_, index) => (
            <div className="flex items-center gap-2" key={index}>
              <input
                type="checkbox"
                className="form-checkbox h-3 w-3 text-primary-gray-800 rounded-sm focus:!ring-primary-gray-800"
              />
              <p className="text-sm font-normal text-primary-gray-800">
                Product
              </p>
            </div>
          ))}
        </div>
      </div>

      <div className="flex flex-wrap gap-2">
        {[...Array(5)].map((_, i) => (
          <div
            key={i}
            className="py-1 px-3 border border-[#E7E6E5] rounded-full font-bold text-sm text-primary-blue-700"
          >
            UI/UX Designer
          </div>
        ))}
      </div>

      <div className="flex justify-between items-center">
        <p className="text-[#696763] text-sm font-semibold">1 Day Ago</p>
        <button className="py-3 px-5 rounded-[8px] border border-[#3996E3] text-[#3996E3] underline font-bold">
          Add Client
        </button>
      </div>
    </div>
  );
}

import { Envelope, MapPin, Phone } from "@phosphor-icons/react";
import moment from "moment";
import React from "react";

function LeadCard({ item }) {
  return (
    <div className="bg-white border border-primary-gray-200 rounded-2xl p-4 space-y-4">
      <div className="flex flex-row items-stretch justify-between">
        <p className="font-medium text-primary-purple-medium font-poppins text-base">
          {item?.founderDictionary?.name}
        </p>
      </div>
      <div className="px-4 flex flex-col space-y-2 text-primary-gray-800 text-xs font-inter">
        <div className="flex flex-row items-center space-x-4">
          <Phone size={16} />
          <p>{item?.contactPhoneNumber}</p>
        </div>
        <div className="flex flex-row items-center space-x-4">
          <Envelope size={16} />
          <p>{item?.contactEmail}</p>
        </div>
        <div className="flex flex-row items-center space-x-4">
          <MapPin size={16} />
          <p>{item?.founderDictionary?.location}</p>
        </div>
      </div>
      <div className="font-semibold flex flex-row items-center space-x-4 py-2 text-primary-gray-800 text-sm font-inter">
        <p>Close Probability: </p>
        <p className="text-primary-green-medium">
          {item?.closeProbability || 0}%
        </p>
      </div>
      <div className="flex flex-row items-center justify-end space-x-4 text-primary-gray-300 text-2xs font-inter">
        <p>In Stage Since</p>
        <p>{moment().format("ll")}</p>
      </div>
    </div>
  );
}

export default LeadCard;

import React from "react";
import { Fade } from "react-awesome-reveal";
import DictionaryCard from "./Cards";

function CardsView({ data }) {
  return (
    <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-5">
      {data?.map((item) => {
        return (
          <Fade triggerOnce direction="bottom" delay={300} duration={300}>
            <DictionaryCard item={item} />
          </Fade>
        );
      })}
    </div>
  );
}

export default CardsView;

import React, { useEffect } from "react";
import { DragDropContext } from "react-beautiful-dnd";
import DraggableElement from "./DraggableElement";
import { useDispatch } from "react-redux";
import { LEAD_STAGES } from "helpers/constants/leads/stages";
const removeFromList = (list, index) => {
  const result = Array.from(list);
  const [removed] = result.splice(index, 1);
  return [removed, result];
};

const addToList = (list, index, element) => {
  const result = Array.from(list);
  result.splice(index, 0, element);
  return result;
};

const lists = LEAD_STAGES;

/**
 * Displays the draggable goals filtered on the status of task
 * @param {Object} data
 * @returns
 */

function DragList({ data, fetching }) {
  let dispatch = useDispatch();
  const [elements, setElements] = React.useState([]);

  useEffect(() => {
    getElements();
  }, [data, fetching]);

  const getElements = async () => {
    let stages = [];
    let arr = [];

    data?.map((item) => {
      if (stages?.includes(item?.stage)) {
        let idx = arr?.findIndex((a) => a?.stage == item?.stage);
        arr[idx].lead?.push(item);
      } else {
        let obj = { stage: item?.stage, lead: [item] };
        arr.push(obj);
        stages.push(item?.stage);
      }
    });

    let temp = {};

    LEAD_STAGES?.map((l) => {
      temp[l] = [];
    });

    arr.map((item) => {
      item?.lead.map((item2) => {
        let stage = item2?.stage || "Shortlist";
        temp[stage]?.push(item2);
      });
    });
    console.log("list", temp);
    setElements(temp);
  };

  // const updateStatus = async (body) => {
  //   let mainBody = {
  //     ...body,
  //   };
  //   try {
  //     const response = await API.updateGoal(body.id, mainBody);
  //     dispatch(updateAGoal({ id: body.id, task: response.data.data }));
  //   } catch (err) {
  //     console.log(err);
  //   }
  // };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    // const listCopy = { ...elements };

    // // Getting the source list
    // const sourceList = listCopy[result.source.droppableId];

    // let tempSource = [];

    // sourceList.map((i) => {
    //   if (i?.goals?.length > 0) {
    //     tempSource = tempSource.concat(i?.goals);
    //   }
    // });

    // // Getting the itm to drag
    // let itemToMove = tempSource?.find((e) => e?.id === result?.draggableId);
    // let tempBody = { ...itemToMove };
    // tempBody.status = result.destination.droppableId;
    // updateStatus(tempBody);

    // // Removing the item from the source list
    // sourceList.map((i) => {
    //   if (itemToMove?.project) {
    //     if (i?.project?.title === itemToMove?.project?.title) {
    //       i.goals = i.goals.filter((e) => e?.id !== itemToMove?.id);
    //     }
    //   } else if (i?.project?.title === "Unassigned") {
    //     i.tasks = i.tasks.filter((e) => e?.id !== itemToMove?.id);
    //   }
    // });

    // listCopy[result.source.droppableId] = sourceList;

    // // Getting the old drop list
    // const oldDropList = listCopy[result.destination.droppableId];

    // // Add the item to the dragged location
    // let allProjects = oldDropList?.map((i) => i?.project?.title);

    // if (allProjects?.includes(itemToMove?.project?.title)) {
    //   oldDropList.map((i) => {
    //     if (itemToMove?.project) {
    //       if (i?.project?.title === itemToMove?.project?.title) {
    //         i.tasks = i.tasks.filter((e) => e?.id !== itemToMove?.id);
    //       }
    //     } else if (i?.project?.title === "Unassigned") {
    //       i.tasks = i.tasks.filter((e) => e?.id !== itemToMove?.id);
    //     }
    //   });
    // } else {
    //   let obj = { project: itemToMove?.project, goals: [itemToMove] };
    //   oldDropList.unshift(obj);
    // }

    // listCopy[result.destination.droppableId] = oldDropList;

    // setElements(listCopy);
  };

  return (
    <div className="max-w-max md:max-w-80vw xl:max-w-70vw mx-auto min-w-max overflow-x-auto">
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="grid grid-cols-7 max-w-max min-w-max divide-x-2">
          {lists.map((listKey) => (
            <DraggableElement
              elements={elements[listKey]}
              key={listKey}
              prefix={listKey}
            />
          ))}
        </div>
      </DragDropContext>
    </div>
  );
}

export default DragList;

import React from "react";

function LeadsCard() {
  return (
    <div className="bg-white rounded-2xl p-6 h-full space-y-4">
      <p className="font-poppins text-primary-gray-800 font-medium leading-5 text-lg">
        Leads
      </p>
      <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto max-w-[95vw] sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <div className="overflow-hidden ring-1 ring-black ring-opacity-5 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-primary-purple-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-4 pl-4 pr-4 text-center text-sm font-semibold text-primary-purple-medium sm:pl-6"
                    >
                      Company
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-4 text-center text-sm font-semibold text-primary-purple-medium"
                    >
                      Stage
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-4 text-center text-sm font-semibold text-primary-purple-medium"
                    >
                      Status
                    </th>
                    <th
                      scope="col"
                      className="px-4 py-4 text-center text-sm font-semibold text-primary-purple-medium"
                    >
                      Close Prob.
                    </th>
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-primary-gray-50 text-center">
                  {[1, 2, 3, 4, 5].map((person) => (
                    <tr key={person}>
                      <td className="whitespace-nowrap py-4 pl-4 pr-4 text-sm font-semibold text-primary-gray-800 sm:pl-6">
                        Name of the company
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-sm ">
                        <div className="bg-primary-purple-lightest text-primary-purple-medium rounded-lg px-4 py-2 font-semibold">
                          Solution
                        </div>
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-sm font-semibold text-primary-purple-dark">
                        Status
                      </td>
                      <td className="whitespace-nowrap px-4 py-4 text-sm text-primary-gray-800 font-semibold">
                        80%
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeadsCard;

import { useDispatch, useSelector } from "react-redux";
import { IoMdCloseCircle } from "react-icons/io";
import { setIsAddProspectModalOpen } from "redux/appConfig";

export default function AddNewProspectModal() {
  const { isAddProspectModalOpen } = useSelector((state) => state.appConfig);
  const dispatch = useDispatch();
  if (!isAddProspectModalOpen) return null;

  const handleClose = () => dispatch(setIsAddProspectModalOpen(false));
  return (
    <section className="fixed w-full h-full bg-black/50 z-50">
      <div className="bg-white absolute w-full h-full bottom-0 max-h-[640px]  rounded-t-2xl shadow-custom py-2">
        <IoMdCloseCircle
          className="text-5xl text-[#363430] absolute -top-12 left-1/2 -translate-x-1/2 cursor-pointer"
          onClick={handleClose}
        />
        <h3 className="text-2xl font-bold tracking-[1px] px-2 py-4 mb-8 border-b border-gray-200">
          Add New Prospect
        </h3>

        <div className="flex flex-col justify-between">
          <form className="px-6 relative">
            <div className="mb-10 flex gap-4 flex-col">
              <label
                className="block text-xs font-semibold text-gray-700"
                htmlFor="startup_name"
              >
                Startup Name
              </label>
              <input
                type="text"
                name="startup_name"
                placeholder="Start typing..."
                className="mt-1 block w-full text-base placeholder:text-[#cfcdc9] border-0 !border-b border-b-[#E7E6E5] px-4 focus:ring-0"
              />
            </div>
            <div className="mb-10 flex gap-4 flex-col">
              <label
                className="block text-xs font-semibold text-gray-700"
                htmlFor="poc_name"
              >
                POC Name
              </label>
              <input
                type="text"
                name="poc_name"
                placeholder="Full Name"
                className="mt-1 block w-full text-base placeholder:text-[#cfcdc9] border-0 !border-b border-b-[#E7E6E5] px-4 focus:ring-0"
              />
            </div>
            <div className="mb-10 flex gap-4 flex-col">
              <label
                className="block text-xs font-semibold text-gray-700"
                htmlFor="poc_email"
              >
                POC Email
              </label>
              <input
                type="text"
                name="poc_email"
                placeholder="example@company.com"
                className="mt-1 block w-full text-base placeholder:text-[#cfcdc9] border-0 !border-b border-b-[#E7E6E5] px-4 focus:ring-0"
              />
            </div>
            <div className="mb-10 flex gap-4 flex-col">
              <label
                className="block text-xs font-semibold text-gray-700"
                htmlFor="poc_mobile"
              >
                POC Mobile
              </label>
              <input
                type="text"
                name="poc_mobile"
                placeholder="00000 00000"
                className="mt-1 block w-full text-base placeholder:text-[#cfcdc9] border-0 !border-b border-b-[#E7E6E5] px-4 focus:ring-0"
              />
            </div>
          </form>
          <div className="flex w-full shadow-custom absolute bottom-0">
            <button className="bg-white text-gray-500 w-full py-6">
              Cancel
            </button>
            <button
              type="submit"
              className="bg-[#3996E3] text-white w-full py-6"
            >
              Add Now
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

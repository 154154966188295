import { getHeader, rolodexInstance } from "config/APIs";

export const fetchLeads = (query = {}) => {
  return rolodexInstance.get(
    `/leads?` + new URLSearchParams(query),
    getHeader()
  );
};

export const getOneLead = (id) => {
  return rolodexInstance.get(`/leads/${id}`, getHeader());
};

export const createLead = (body) => {
  return rolodexInstance.post(`/leads`, body, getHeader());
};
export const updateLead = (id, body) => {
  return rolodexInstance.patch(`/leads/${id}`, body, getHeader());
};

export const deleteLead = (id) => {
  return rolodexInstance.delete(`/leads/${id}`, getHeader());
};

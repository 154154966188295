import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  user: JSON.parse(localStorage.getItem("admin")) || {},
  token: localStorage.getItem("token") || "",
};

// Storing User details and Token
export const chronosUser = createSlice({
  name: "user",
  initialState,
  reducers: {
    userUpdate: (state, action) => {
      if (action.payload?.user) {
        let u = action.payload?.user;
        state.user = u;
        localStorage.setItem("admin", JSON.stringify(u));
      }
      if (action.payload?.token) {
        let t = action.payload?.token;
        state.token = t;
        localStorage.setItem("token", t);
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const { userUpdate } = chronosUser.actions;

export default chronosUser.reducer;

import LeadsCard from "components/Dashboard/Leads";
import ClientBase from "components/Dashboard/Leads/ClientBase";
import LeadDistribution from "components/Dashboard/Leads/LeadDistribution";
import Stages from "components/Dashboard/Stages";
import TasksInFocus from "components/Dashboard/Tasks";
import YourTeam from "components/Dashboard/Team";
import Values from "components/Dashboard/Values";
import React from "react";

function Dashboard() {
  return (
    <div className="grid lg:grid-cols-12 gap-y-6 gap-x-8">
      <div className="lg:col-span-6">
        <LeadsCard />
      </div>
      <div className="lg:col-span-6">
        <TasksInFocus />
      </div>
      <div className="lg:col-span-12">
        <Stages />
      </div>
      <div className="lg:col-span-7">
        <Values />
      </div>
      <div className="lg:col-span-5">
        <YourTeam />
      </div>
      <div className="lg:col-span-5">
        <LeadDistribution />
      </div>
      <div className="lg:col-span-7">
        <ClientBase />
      </div>
    </div>
  );
}

export default Dashboard;

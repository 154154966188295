import moment from "moment";
import React from "react";

function TasksInFocus() {
  return (
    <div className="bg-white rounded-2xl p-6 h-full space-y-6">
      <p className="font-poppins text-primary-gray-800 font-medium leading-5 text-lg">
        Tasks in Focus Today
      </p>
      <div className="w-full flex flex-col space-y-4">
        {[1, 2, 3, 4]?.map((t) => {
          return (
            <div className="w-full rounded-lg bg-primary-gray-50 border-primary-gray-200 px-4 py-2 space-y-2">
              <div className="flex flex-row items-stretch space-x-2">
                <p className="text-sm text-primary-gray-800 font-inter font-light">
                  Task Title
                </p>
              </div>
              <div className="flex flex-row items-stretch space-x-2">
                <p className="text-xs text-primary-gray-350 font-inter font-light">
                  Due on: {moment().format("ll")}
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default TasksInFocus;

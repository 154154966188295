import { configureStore } from "@reduxjs/toolkit";

import appConfigReducer from "./appConfig";
import toasterReducer from "./toaster";
import userReducer from "./user";
import usersReducer from "./users";
import dictionaryReducer from "./dictionary";
import channelReducer from "./channel";

const store = configureStore({
  reducer: {
    appConfig: appConfigReducer,
    toaster: toasterReducer,
    user: userReducer,
    dictionary: dictionaryReducer,
    channel: channelReducer,
    users: usersReducer,
  },
});

export default store;

import { Fragment, useState } from "react";
import { Menu, Transition } from "@headlessui/react";
import WideModalsWrapper from "components/Comman/ModalsWrapper/WideModalWrapper";
import LogoutModal from "./LogoutModal";
import { useDispatch, useSelector } from "react-redux";
import { Moon, Sun } from "@phosphor-icons/react";
import { changeTheme } from "redux/appConfig";

function TopBar() {
  const dark = useSelector((state) => state.appConfig.dark);
  const dispatch = useDispatch();
  const [logOut, setLogOut] = useState(false);
  const onLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("role");
    localStorage.removeItem("navList");
    localStorage.removeItem("selectedTab");
    window.location.href = window.location.origin + "/";
  };
  return (
    <>
      <WideModalsWrapper
        isOpen={logOut}
        closeModal={() => {
          setLogOut(false);
        }}
        component={<LogoutModal onLogout={onLogout} />}
      />

      <div className="fixed w-full top-0 right-0 left-0 px-3 py-2 top-nav flex lg:hidden flex-row items-stretch justify-between">
        <a href="/">
          <div className="flex flex-row items-center space-x-1">
            <h1 className="font-bold font-poppins text-xl text-white">
              Rolodex
            </h1>
          </div>
        </a>

        <div className="flex flex-row items-center space-x-4">
          <div
            onClick={() => {
              var body = document.getElementById("root");
              var appWrapper = document.getElementById("appWrapper");

              if (dark) {
                body.classList.remove("dark");
                appWrapper.classList.remove("dark");
                body.classList.add("light");
                appWrapper.classList.add("light");
              } else {
                body.classList.add("dark");
                appWrapper.classList.add("dark");
                body.classList.remove("light");
                appWrapper.classList.remove("light");
              }
              dispatch(changeTheme());
            }}
          >
            {dark ? (
              <Sun color="#fff" size={20} />
            ) : (
              <Moon color="#fff" size={20} />
            )}
          </div>
          <Menu as="div" className="relative block text-left">
            <Menu.Button className="">
              {/* {dark ? ( */}
              <img
                src="/assets/svg/navigation/menuDark.svg"
                className="h-4 w-4 mt-1.5 object-cover"
                alt="logo"
              />
              {/* ) : (
                <img
                  src="/assets/svg/navigation/menu.svg"
                  className="h-4 w-4 mt-1.5 object-cover"
                  alt="logo"
                />
              )} */}
            </Menu.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                style={{ borderRadius: "20px 0px 20px 20px" }}
                className={`origin-top-right absolute right-0 w-24 z-10 shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none `}
              >
                <div className="py-1 max-h-64 overflow-y-auto relative z-50">
                  {[1].map((item) => {
                    return (
                      <Menu.Item>
                        {({ active }) => (
                          <div
                            onClick={() => {
                              setLogOut(true);
                            }}
                            className="text-black font-inter font-normal block px-4 py-2 text-2xs cursor-pointer"
                          >
                            Logout
                          </div>
                        )}
                      </Menu.Item>
                    );
                  })}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>
        </div>
      </div>
    </>
  );
}

export default TopBar;

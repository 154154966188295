import { createSlice } from "@reduxjs/toolkit";
import { getAllDictionary } from "config/APIs/sales/founderDictionary";

const initialState = {
  list: [],
  fetching: true,
};

// Storing members
export const dictionary = createSlice({
  name: "dictionary",
  initialState,
  reducers: {
    updateList: (state, action) => {
      let list = action.payload.list;
      state.list = list;
      state.fetching = false;
    },
  },
});

// Action creators are generated for each case reducer function
export const { updateList } = dictionary.actions;

export default dictionary.reducer;

export function fetchAllDictionary() {
  return async (dispatch) => {
    try {
      const response = await getAllDictionary();
      if (response.status === 200) {
        let data = response.data.data?.response;

        dispatch(updateList({ list: data }));
      }
    } catch (err) {
      console.log("Fetch all dictionary: ", err);
    }
  };
}

// Kanban board
import DragList from "./DragList";

/**
 * BoardView Component
 * @param {Object} data
 * @returns
 */

function BoardView({ data, fetching }) {
  return (
    <div className="kanban-wrapper mx-auto overflow-x-auto">
      <DragList fetching={fetching} data={data} />
    </div>
  );
}

export default BoardView;

import React from "react";

const list = [
  {
    image: "/assets/images/values/1.png",
    bgColor: "#E7F9FD",
    color: "#1A6BE5",
    name: "Adaptibility",
  },
  {
    image: "/assets/images/values/2.png",
    bgColor: "#FDF7E9",
    color: "#FFBC00",
    name: "Continuous Learning",
  },
  {
    image: "/assets/images/values/3.png",
    bgColor: "#EDFDDD",
    color: "#33A329",
    name: "Being Inquisituive",
  },
  {
    image: "/assets/images/values/4.png",
    bgColor: "#EBEDF7",
    color: "#6775F5",
    name: "Accountability",
  },
  {
    image: "/assets/images/values/5.png",
    bgColor: "#FFE9E5",
    color: "#E72113",
    name: "Empathy",
  },
];

function Values() {
  return (
    <div className="bg-white rounded-2xl p-6 h-full space-y-4">
      <p className="font-poppins text-primary-gray-800 font-medium leading-5 text-lg">
        Values to Uphold
      </p>
      <div className="w-full flex flex-row flex-wrap items-stretch justify-center px-4">
        {list?.map((t) => {
          return (
            <div className="m-4 flex flex-col items-center space-y-1.5 px-2.5">
              <div
                style={{ background: t?.bgColor }}
                className="flex flex-row items-center justify-center rounded-full w-[120px] h-[120px]"
              >
                <img src={t?.image} className="w-16 h-16" />
              </div>

              <p
                style={{ color: t?.color }}
                className="text-base font-poppins font-medium"
              >
                {t?.name}
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default Values;

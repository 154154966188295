export const SHORTLIST = "Shortlist";
export const TOUCHBASE = "Touchbase";
export const REQUIREMENT_GATHERING = "Requirement Gathering";
export const SOLUTION = "Solution";
export const PROPOSAL = "Proposal";
export const APPROVAL = "Approval";
export const INVOICING = "Invoicing";

export const LEAD_STAGES = [
  SHORTLIST,
  TOUCHBASE,
  REQUIREMENT_GATHERING,
  SOLUTION,
  PROPOSAL,
  APPROVAL,
  INVOICING,
];

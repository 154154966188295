import { FiExternalLink } from "react-icons/fi";
import { FaUser } from "react-icons/fa";
import { MdContentCopy } from "react-icons/md";
import { HiOutlineMail } from "react-icons/hi";
import { IoCallOutline } from "react-icons/io5";
import CustomTabs from "./Tabs";
import { useState } from "react";
import NewTalentRequestModal from "./Tabs/NewTalentRequestModal";

export default function Client() {
  const [isOpen, setIsOpen] = useState(false);
  const handleChangeModal = () => setIsOpen(!isOpen);
  return (
    <section className="pt-16 pb-[120px] px-4 bg-white">
      <div className="flex flex-col gap-2 items-center mb-8">
        <img
          src="/assets/images/defaultCard.png"
          alt="defaultCard"
          className="w-12 h-12 rounded"
        />
        <h1 className="text-2xl font-bold text-black">Startup Name</h1>
        <div className="flex gap-2">
          <a
            href="#"
            className="flex gap-2 items-center text-[#3996E3] text-sm font-bold underline"
          >
            <span>View Story</span>
            <FiExternalLink className="text-sm" />
          </a>
          <span className="text-[#9C9A96] font-normal text-sm">(24%)</span>
        </div>
      </div>
      <div className="py-6 px-4 bg-[#fafafa] flex flex-col gap-4 items-center mb-8">
        <div className="flex gap-2 items-center">
          <FaUser className="text-[#3996E3]" />
          <span className="text-sm text-[#363430] font-bold">John Doe</span>
        </div>
        <div className="flex items-center gap-4 w-full">
          <div className="bg-white py-2 rounded-[8px] px-4 gap-3 flex items-center w-full">
            <HiOutlineMail className="text-[#CFCDC9]" />
            <span className="text-[#363430] font-normal text-sm">
              email@example.com
            </span>
          </div>
          <MdContentCopy className="text-[#696763] text-2xl" />
        </div>
        <div className="flex items-center gap-4 w-full ">
          <div className="bg-white py-2 rounded-[8px] px-4 gap-3 flex items-center w-full">
            <IoCallOutline className="text-[#CFCDC9]" />
            <span className="text-[#363430] font-normal text-sm">
              00000 00000
            </span>
          </div>
          <MdContentCopy className="text-[#696763] text-2xl" />
        </div>
      </div>
      <CustomTabs handleChange={handleChangeModal} />
      <NewTalentRequestModal isOpen={isOpen} handleClose={handleChangeModal} />
    </section>
  );
}

import { useEffect, useState, useRef } from "react";

function Tabs({ tabs, selectedTab, setSelectedTab }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const [tabUnderlineWidth, setTabUnderlineWidth] = useState(0);
  const [tabUnderlineLeft, setTabUnderlineLeft] = useState(0);

  const tabsRef = useRef([]);

  useEffect(() => {
    function setTabPosition() {
      const currentTab = tabsRef.current[activeTabIndex];
      setTabUnderlineLeft(currentTab?.offsetLeft ?? 0);
      setTabUnderlineWidth(currentTab?.clientWidth ?? 0);
    }

    setTabPosition();
    window.addEventListener("resize", setTabPosition);

    return () => window.removeEventListener("resize", setTabPosition);
  }, [activeTabIndex]);

  useEffect(() => {
    let idx = tabs?.findIndex((a) => a?.value == selectedTab);
    setActiveTabIndex(idx);
  }, [selectedTab]);

  return (
    <div className="px-5 lg:px-0 pt-5 pb-2 relative">
      <div className="flex flex-row items-center justify-start overflow-x-auto">
        {tabs.map((item, idx) => {
          console.log(item, selectedTab);
          return (
            <div
              key={idx}
              id={`selected-${item.value}`}
              ref={(el) => (tabsRef.current[idx] = el)}
              onClick={() => {
                setSelectedTab(item?.value);
                setActiveTabIndex(idx);
              }}
              className="min-w-max"
            >
              <div
                className={`flex px-3 py-1 flex-row cursor-pointer text-xs lg:text-sm items-end justify-center font-poppins text-center ${
                  selectedTab == item?.value
                    ? "font-medium bg-primary-gray-100 text-primary-purple-dark"
                    : "font-medium text-primary-gray-800"
                }`}
              >
                {item?.label}
              </div>
            </div>
          );
        })}
      </div>

      <span
        className="absolute bottom-1 rounded-sm block h-[3px] bg-primary-purple-dark transition-all duration-300"
        style={{ left: tabUnderlineLeft, width: tabUnderlineWidth }}
      />
    </div>
  );
}

export default Tabs;

function BoxedTabs({ tabs, selectedTab, setSelectedTab }) {
  const [activeTabIndex, setActiveTabIndex] = useState(0);

  useEffect(() => {
    if (activeTabIndex !== tabs.findIndex((i) => i?.value == selectedTab)) {
      console.log(
        ":::",
        activeTabIndex,
        selectedTab,
        tabs.findIndex((i) => i?.value == selectedTab)
      );
      setActiveTabIndex(tabs.findIndex((i) => i?.value == selectedTab));
    }
  }, [selectedTab]);

  return (
    <div className="mb-1.5 relative bg-gray-50 px-2 py-1 rounded-lg border border-gray-200 max-w-[90vw] overflow-x-auto">
      <div
        className={`flex flex-row items-center justify-start space-x-4 overflow-x-auto`}
      >
        {tabs.map((item, idx) => {
          return (
            <div
              key={idx}
              id={`selected-${item.value}`}
              onClick={() => {
                setSelectedTab(item?.value);
                setActiveTabIndex(idx);
              }}
              className="min-w-max"
            >
              <div
                className={`flex px-6 py-2 flex-row cursor-pointer text-primary-gray-600 font-poppins hover:font-semibold text-center ${
                  selectedTab == item?.value
                    ? "text-primary-purple-darker bg-primary-purple-lightest font-medium rounded-lg"
                    : "font-normal"
                }`}
              >
                {item?.label}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export { BoxedTabs };

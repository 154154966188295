import SimpleInput from "components/Comman/Inputs/SimpleInput";
import { DoorOpen, MapPin } from "@phosphor-icons/react";

function CompanyDetails({ data, setData }) {
  return (
    <>
      <div className="flex flex-col items-start w-full space-y-6">
        <label
          htmlFor="Title"
          className="text-base text-primary-gray-800 leading-4 font-poppins font-medium"
        >
          Company Details
        </label>
        <div className="px-6 grid grid-cols-7 gap-x-4 w-full">
          <DoorOpen className="text-primary-purple-dark" size={24} />
          <div className="col-span-2 input-label">Company Name:</div>
          <div className="col-span-4">
            <SimpleInput
              srOnly
              field="companyName"
              details={data}
              setDetails={setData}
              placeholder="Start typing..."
            />
          </div>
        </div>
        <div className="px-6 grid grid-cols-7 gap-x-4 w-full">
          <MapPin className="text-primary-purple-dark" size={24} />
          <div className="col-span-2 input-label">Location:</div>
          <div className="col-span-4">
            <SimpleInput
              srOnly
              field="companyLocation"
              details={data}
              setDetails={setData}
              placeholder="Start typing..."
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default CompanyDetails;

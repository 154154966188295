import { Chair } from "@phosphor-icons/react";
import { classNames } from "helpers/utils/classNames";

const steps = [
  { name: "Shortlist", count: 8 },
  { name: "Touchbase", count: 4 },
  { name: "Requirements Gathering", count: 0 },
  { name: "Solution", count: 2 },
  { name: "Proposal", count: 1 },
  { name: "Approval", count: 3 },
  { name: "Invoicing", count: 1 },
];

export default function Stages() {
  return (
    <div className="bg-white rounded-2xl p-6 h-full space-y-4 max-w-[100vw] overflow-x-auto">
      <nav aria-label="Progress">
        <ol role="list" className="flex items-center justify-center">
          {steps.map((step, stepIdx) => (
            <li
              key={step.name}
              className={classNames(
                stepIdx !== steps.length - 1 ? "pr-8 sm:pr-20" : "",
                "relative"
              )}
            >
              <>
                <div
                  className="absolute inset-0 flex items-center bottom-[30%]"
                  aria-hidden="true"
                >
                  <div className="h-0.5 w-full bg-primary-purple-lighter" />
                </div>
                <div className="flex flex-col items-center">
                  <div className="relative text-primary-purple-medium flex h-14 w-14 items-center justify-center rounded-full bg-white border border-primary-purple-lighter">
                    <span className="">{step.count}</span>
                  </div>
                  <p className="mt-2 line-clamp-1 text-primary-purple-dark text-sm font-inter font-semibold">
                    {step?.name}
                  </p>
                </div>
              </>
            </li>
          ))}
        </ol>
      </nav>
    </div>
  );
}

import { User } from "@phosphor-icons/react";
import moment from "moment";
import React from "react";

function YourTeam() {
  return (
    <div className="bg-white rounded-2xl p-6 h-full space-y-6">
      <p className="font-poppins text-primary-gray-800 font-medium leading-5 text-lg">
        Your Team
      </p>
      <div className="w-full grid lg:grid-cols-2 gap-4">
        {[1, 2, 3, 4, 5, 6, 7, 8]?.map((t) => {
          return (
            <div className="w-full rounded-lg shadow bg-primary-gray-50 border-primary-gray-200 p-4 space-x-4 flex flex-row items-start">
              <div className="flex flex-row items-center justify-center bg-primary-purple-lightest text-primary-purple-darkest rounded-full w-10 h-10">
                <User size={26} />
              </div>
              <div className="flex flex-col items-start">
                <p className="text-sm text-primary-purple-dark font-poppins font-medium">
                  Name
                </p>
                <p className="text-xs text-primary-gray-800 font-inter font-thin">
                  Role
                </p>
                <p className="text-xs text-primary-gray-800 font-inter font-thin">
                  Designation
                </p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default YourTeam;

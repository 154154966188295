import { FaRegHeart, FaLinkedin } from "react-icons/fa";
import { MdArrowForward, MdBolt } from "react-icons/md";

export default function Card() {
  return (
    <div className="p-4 bg-white rounded-2xl shadow-customLarge flex flex-col gap-6">
      <div className="flex justify-between items-center gap-6">
        <FaRegHeart className="text-[#CFCDC9] text-2xl" />
        <div className="flex items-center gap-2 text-[#3996E3] text-sm font-bold">
          View details
          <MdArrowForward className="text-[#3996E3] text-lg" />
        </div>
      </div>

      <div className="flex gap-5 items-start">
        <img
          src="/assets/images/defaultCard.png"
          alt="defaultCard"
          className="w-20 h-20 rounded"
        />
        <div className="flex flex-col">
          <p className="text-primary-gray-800 text-xl font-bold">
            Startup Name Here
          </p>
          <p className="text-[#696763] text-sm font-semibold">Industry Type</p>
          <div className="flex items-center gap-2 mt-2">
            <FaLinkedin className="text-[#9C9A96] text-base" />
            <a className="text-[#696763] text-xs font-normal">
              linkedin.com/company/startupname
            </a>
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-8">
        <p className="text-xs font-normal text-[#363430]">
          Research Completion
        </p>
        <div className="flex items-center gap-4">
          <div className="w-full bg-[#F3F2F2] rounded-full h-2">
            <div className="bg-[#A0D3F8] h-2 rounded-full w-[45%]"></div>
          </div>
          <p className="text-xs font-normal text-[#363430]">45%</p>
        </div>
      </div>

      <div className="flex justify-between items-center">
        <div className="text-[#696763] text-2xs flex items-center gap-1">
          <div className="h-1.5 w-1.5 rounded-full bg-primary-green-medium"></div>
          Research Active
        </div>
        <button className="p-2.5 rounded-[8px] bg-[#F5FBFF] text-[#3996E3] flex gap-2 items-center font-bold">
          <MdBolt className="text-[#3996E3] text-lg" />
          Request Priority Research
        </button>
      </div>
    </div>
  );
}

import Card from "./Card";

export default function HotLeads() {
  return (
    <section className="py-6 px-4 bg-white">
      <div className="my-4 flex flex-col gap-6 max-w-lg mx-auto">
        {[...Array(4)].map((_, i) => (
          <Card key={i} />
        ))}
      </div>
    </section>
  );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  dark: false,
  isAddProspectModalOpen: false,
};

// Open toggle for Mobile Side Navigation
export const appConfig = createSlice({
  name: "appConfig",
  initialState,
  reducers: {
    changeTheme: (state) => {
      state.dark = !state.dark;
    },
    setIsAddProspectModalOpen: (state, action) => {
      state.isAddProspectModalOpen = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { changeTheme, setIsAddProspectModalOpen } = appConfig.actions;

export default appConfig.reducer;

import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import RequirementCard from "./RequirementCard";
import TalentRequestCard from "./TalentRequestCard";
import { FaPlus } from "react-icons/fa";
import NewTalentRequestModal from "./NewTalentRequestModal";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      className="w-full h-full"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      {...other}
    >
      {value === index && (
        <Box
          sx={{
            py: "32px",
            position: "relative",
            height: "100%",
          }}
        >
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CustomTabs({ handleChange: handleChangeModal }) {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box sx={{ borderBottom: "4px", borderColor: "#3996E3" }}>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Requirements (1)" {...a11yProps(0)} />
            <Tab label="Active Requests (1)" {...a11yProps(1)} />
          </Tabs>
        </Box>
        <CustomTabPanel value={value} index={0}>
          <RequirementCard />
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <TalentRequestCard />

          <button
            className="mt-10 ml-auto flex gap-2 items-center py-[18px] px-6 rounded-2xl text-white"
            style={{
              background:
                "linear-gradient(154deg, #8DA1FC 7.06%, #3996E3 88.02%), #3A3A3A",
            }}
            onClick={handleChangeModal}
          >
            <FaPlus className="text-sm" />
            <span className="underline font-bold text-sm">New Request</span>
          </button>
        </CustomTabPanel>
      </Box>
    </>
  );
}

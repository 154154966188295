import Card from "./Card";
import { IoMdSearch } from "react-icons/io";
import Dropdown from "../Comman/Dropdown/Dropdown";
import { MdSort } from "react-icons/md";

export default function MyClients() {
  return (
    <section className="py-6 px-4 bg-white">
      <div className="flex items-center gap-[14px]">
        <div className="relative w-full">
          <div className="absolute inset-y-0 start-0 flex items-center pl-6 pointer-events-none">
            <IoMdSearch className="text-gray-400 text-lg" />
          </div>
          <input
            type="search"
            id="default-search"
            className="block w-full py-4 pl-14 pr-6 font-light text-base text-gray-900 rounded-full border border-gray-200"
            placeholder="Search for a client..."
            required
          />
        </div>
      </div>
      <div className="flex gap-2.5 my-6 justify-between max-w-md mx-auto">
        <Dropdown label={"Sort"} startIcon={<MdSort className="text-lg" />} />
        <Dropdown label={"Industry"} />
        <Dropdown label={"Research Status"} />
      </div>
      <div className="flex my-6">
        <label className="inline-flex items-center cursor-pointer">
          <span className="mr-3 text-sm font-medium text-gray-900 dark:text-gray-300">
            Hot Leads Only
          </span>
          <input type="checkbox" value="" className="sr-only peer" />
          <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300  rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[18px] after:w-[18px] after:transition-all peer-checked:bg-[#3996E3]"></div>
        </label>
      </div>
      <div className="my-4 flex flex-col gap-6 max-w-lg mx-auto">
        {[...Array(4)].map((_, i) => (
          <Card key={i} />
        ))}
      </div>
    </section>
  );
}

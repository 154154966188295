import React from "react";

function LeadTable({ heading, color, data }) {
  return (
    <div className="mt-8 flow-root">
      <div className="-mx-4 -my-2 overflow-x-auto max-w-[95vw] sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-hidden sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-white">
                <tr>
                  <th
                    style={{ color: color }}
                    scope="col"
                    className="py-4 pl-4 pr-4 text-center text-sm font-semibold sm:pl-6"
                  >
                    {heading}
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-center text-sm font-semibold text-primary-purple-darkest"
                  >
                    Stage
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-center text-sm font-semibold text-primary-purple-darkest"
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-4 py-4 text-center text-sm font-semibold text-primary-purple-darkest"
                  >
                    Close Prob.
                  </th>
                </tr>
              </thead>
              <tbody className="text-center mt-2 space-y-1 bg-white">
                {data?.map((item) => (
                  <tr className="bg-white py-2">
                    <td className="whitespace-nowrap py-1 text-sm font-semibold text-primary-gray-800">
                      <div
                        style={{ borderColor: color }}
                        className="border-l-[6px]  py-1 pl-4 pr-4 sm:pl-6"
                      >
                        <div className="px-4 bg-primary-gray-50 py-2">
                          {item?.founderDictionary?.name}
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap py-1 text-sm ">
                      <div className="py-1 px-4">
                        <div className="bg-primary-purple-lightest text-primary-purple-medium rounded-lg px-4 py-2 font-semibold">
                          {item?.stage}
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap py-1 text-sm font-semibold text-primary-purple-dark">
                      <div className="py-1 px-4 ">
                        <div className="px-4 bg-primary-gray-50 py-2">
                          {item?.status}
                        </div>
                      </div>
                    </td>
                    <td className="whitespace-nowrap py-1 text-sm text-primary-gray-800 font-semibold">
                      <div className="py-1 px-4 ">
                        <div className="px-4 bg-primary-gray-50 py-2">
                          {item?.closeProbability}%
                        </div>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LeadTable;

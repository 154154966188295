import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const labels = ["January", "February", "March", "April", "May", "June", "July"];

export const data = {
  labels,
  datasets: [
    {
      label: "Dataset 1",
      data: [10, 24, 1, 34, 5, 4],
      backgroundColor: "#903795",
    },
    {
      label: "Dataset 2",
      data: [10, 24, 1, 34, 5, 4],
      backgroundColor: "#F4C4F7",
    },
  ],
};
export const options = {
  responsive: true,
  plugins: {
    legend: {
      dispaly: false,
    },
  },
};
function ClientBase() {
  return (
    <div className="bg-white rounded-2xl p-6 h-full space-y-6">
      <p className="font-poppins text-primary-gray-800 font-medium leading-5 text-lg">
        Client Base
      </p>
      <div className="w-full block">
        <Bar options={options} data={data} />
      </div>
    </div>
  );
}

export default ClientBase;

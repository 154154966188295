import { MdBolt, MdSort } from "react-icons/md";
import Card from "./Card";
import { IoMdSearch } from "react-icons/io";
import Dropdown from "../Comman/Dropdown/Dropdown";

export default function HotLeads() {
  return (
    <section className="py-6 px-4 bg-white">
      <div className="flex items-center gap-[14px]">
        <div className="relative w-full">
          <div className="absolute inset-y-0 start-0 flex items-center pl-6 pointer-events-none">
            <IoMdSearch className="text-gray-400 text-lg" />
          </div>
          <input
            type="search"
            id="default-search"
            className="block w-full py-4 pl-14 pr-6 font-light text-base text-gray-900 rounded-full border border-gray-200"
            placeholder="Search for a startup..."
            required
          />
        </div>
        <div className="p-2 pl-1 rounded-[8px] flex items-center bg-[#FAFAFA]">
          <MdBolt className="text-[#3996E3] text-lg" />
          <span>5</span>
        </div>
      </div>
      <div className="flex gap-2.5 my-6 justify-between max-w-md mx-auto">
        <Dropdown label={"Sort"} startIcon={<MdSort className="text-lg" />} />
        <Dropdown label={"Industry"} />
        <Dropdown label={"Research Status"} />
      </div>
      <div className="my-4 flex flex-col gap-6 max-w-lg mx-auto">
        {[...Array(4)].map((_, i) => (
          <Card key={i} />
        ))}
      </div>
    </section>
  );
}

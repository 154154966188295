import {
  Calendar,
  Crown,
  FileImage,
  MagnifyingGlass,
  ShootingStar,
  Stack,
} from "@phosphor-icons/react";
import {
  DASHBOARD,
  DICTIONARY,
  HOT_LEADS,
  LEADS,
  MY_CLIENTS,
  PROSPECT_BANK,
  SEARCH,
  SELECTED_CLIENTS,
  STACKS,
} from "./tabs";
import { ReactComponent as HotLeads } from "assets/icons/HotLeads.svg";
import { ReactComponent as SelectedHotLeads } from "assets/icons/SelectedHotLeads.svg";
import { ReactComponent as ProspectBank } from "assets/icons/ProspectBank.svg";
import { ReactComponent as SelectedProspectBank } from "assets/icons/SelectedProspectBank.svg";
import { ReactComponent as Clients } from "assets/icons/Clients.svg";
import { ReactComponent as SelectedClients } from "assets/icons/SelectedClients.svg";
import { ReactComponent as Dashboard } from "assets/icons/Dashboard.svg";
import { setIsAddProspectModalOpen } from "redux/appConfig";

const mobileBottomNav = [
  // {
  //   name: STACKS,
  //   path: "/stacks",
  //   icon: <Stack size={32} />,
  // },
  // {
  //   name: DICTIONARY,
  //   path: "/founderDictionary",
  //   icon: <ShootingStar size={32} />,
  // },
  {
    name: DASHBOARD,
    path: "/dashboard",
    icon: <Dashboard />,
    selectedIcon: <Dashboard />,
  },
  {
    name: MY_CLIENTS,
    path: "/my-clients",
    icon: <Clients />,
    selectedIcon: <SelectedClients />,
  },
  {
    name: "Add Prospect",
    action: setIsAddProspectModalOpen,
    image: "/assets/images/AddProspect.png",
  },
  // {
  //   name: LEADS,
  //   path: "/leads",
  //   icon: <Crown size={32} />,
  // },
  {
    name: HOT_LEADS,
    path: "/hot-leads",
    icon: <HotLeads />,
    selectedIcon: <SelectedHotLeads />,
  },
  // {
  //   name: SEARCH,
  //   path: "/search",
  //   icon: <MagnifyingGlass size={32} />,
  // },
  {
    name: PROSPECT_BANK,
    path: "/prospect-bank",
    icon: <ProspectBank />,
    selectedIcon: <SelectedProspectBank />,
  },
];
const laptopNav = [
  {
    name: DASHBOARD,
    path: "/dashboard",
    icon: <FileImage size={25} />,
  },
  {
    name: DICTIONARY,
    path: "/founderDictionary",
    icon: <ShootingStar size={32} />,
  },
  {
    name: LEADS,
    path: "/leads",
    icon: <Calendar size={32} />,
  },
];

export { mobileBottomNav, laptopNav };

import { MagnifyingGlass } from "@phosphor-icons/react";
import { useState } from "react";

const AutoComplete = ({
  list,
  label,
  value,
  setValue,
  srOnly = false,
  isDisabled,
  placeholder,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [suggestionIndex, setSuggestionIndex] = useState(0);
  const [suggestionsActive, setSuggestionsActive] = useState(false);
  const [searchvalue, setsearchValue] = useState(
    list?.find((l) => l?.value == value)?.label || ""
  );

  const handleChange = (e) => {
    const query = e.target.value.toLowerCase();
    setsearchValue(query);
    if (query.length > 1) {
      const filterSuggestions = list.filter(
        (suggestion) => suggestion?.label?.toLowerCase().indexOf(query) > -1
      );
      setSuggestions(filterSuggestions);
      setSuggestionsActive(true);
    } else {
      setSuggestionsActive(false);
    }
  };

  const handleClick = (suggestion) => {
    setSuggestions([]);
    setsearchValue(suggestion?.label);
    setValue(suggestion);
    setSuggestionsActive(false);
  };

  const handleKeyDown = (e) => {
    // UP ARROW
    if (e.keyCode === 38) {
      if (suggestionIndex === 0) {
        return;
      }
      setSuggestionIndex(suggestionIndex - 1);
    }
    // DOWN ARROW
    else if (e.keyCode === 40) {
      if (suggestionIndex - 1 === suggestions.length) {
        return;
      }
      setSuggestionIndex(suggestionIndex + 1);
    }
    // ENTER
    else if (e.keyCode === 13) {
      setsearchValue(suggestions[suggestionIndex]?.label);
      setValue(suggestions[suggestionIndex]);
      setSuggestionIndex(0);
      setSuggestionsActive(false);
    }
  };

  const Suggestions = () => {
    return (
      <ul className="suggestions bg-white rounded-b py-2 border-l border-r border-b border-primary-gray-200 w-full font-inter text-primary-gray-800 text-sm absolute top-12 z-20">
        {suggestions.map((suggestion, index) => {
          return (
            <li
              className={`px-4 py-2 ${
                index === suggestionIndex
                  ? "bg-primary-purple-lightest text-primary-purple-darker font-semibold"
                  : "font-medium"
              }`}
              key={index}
              onClick={() => handleClick(suggestion)}
            >
              {suggestion?.label}
            </li>
          );
        })}
      </ul>
    );
  };

  return (
    <div className="flex flex-col items-start w-full relative">
      {!srOnly && (
        <label htmlFor={label} className={srOnly ? "sr-only" : "input-label"}>
          {label}
        </label>
      )}
      <div className="w-full flex flex-row items-center space-x-1.5 px-2 pb-1.5 border-b border-primary-gray-200">
        <MagnifyingGlass className="text-primary-purple-dark" size={24} />
        <input
          placeholder={placeholder}
          className={`w-full appearance-none text-primary-gray-800 font-inter font-normal text-sm leading-6 placeholder:text-gray-200 bg-transparent focus:outline-none focus:ring-transparent focus:border-transparent border-0`}
          type="text"
          value={searchvalue}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
        />
      </div>
      {suggestionsActive && <Suggestions />}
    </div>
  );
};

export default AutoComplete;

import { ArrowRightIcon, HeartIcon } from "@heroicons/react/outline";
import {
  LocationMarkerIcon,
  MailIcon,
  PhoneIcon,
} from "@heroicons/react/solid";
import React from "react";

function DictionaryCard({ item }) {
  return (
    <div className="dictionaryCard text-primary-pink-darkest dark:text-white p-4 rounded-md flex flex-col items-start relative">
      <div className="absolute top-4 right-4">
        <HeartIcon className="w-5 h-5" />
      </div>
      <p className="name text-xl font-inter font-bold">{item?.name}</p>
      <p className="type h-5 text-xs mt-0.5 font-inter bg-primary-pink-lightest dark:bg-white bg-opacity-80 text-primary-pink-dark px-3 py-0.5 rounded">
        {item?.type}
      </p>
      <div className="flex flex-row items-center space-x-2 mt-2.5">
        <LocationMarkerIcon className="w-4 h-4" />
        <p className="name text-sm font-inter">{item?.location}</p>
      </div>
      <div className="mt-5 w-full flex flex-row justify-between items-center space-x-2.5">
        <div className="flex flex-row items-center space-x-5">
          <div className="flex flex-row items-center space-x-2">
            <PhoneIcon className="w-4 h-4" />
            {/* <p className="name text-sm font-inter">{item?.phoneNumber}</p> */}
          </div>
          <div className="flex flex-row items-center space-x-2">
            <MailIcon className="w-4 h-4" />
            {/* <p className="name text-sm font-inter">{item?.email}</p> */}
          </div>
        </div>
        <div className="flex flex-row items-center space-x-2">
          <p className="name text-sm font-inter underline">View Linkedin</p>
          <ArrowRightIcon className="w-4 h-4" />
        </div>
      </div>
    </div>
  );
}

export default DictionaryCard;
